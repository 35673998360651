import React from "react";

interface ProgressBarProps {
  progress: number;
  title: string; // Added title prop
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, title }) => {
  return (
    <div>
      <div
        style={{
          border: "1px solid #000",
          borderRadius: "5px",
          width: "100%",
          background: "#e0e0e0",
        }}
      >
        <div
          style={{
            width: `${progress}%`,
            background: "#76c7c0",
            height: "20px",
            borderRadius: "5px",
            transition: "width 0.3s ease-in-out",
          }}
        />
      </div>
      <div style={{ textAlign: "center", marginTop: "5px" }}>{title}</div>{" "}
      {/* Added title */}
    </div>
  );
};

export default ProgressBar;
